import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/markoletic/Documents/Code/armada-js-website/src/components/markdown-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CODE OF CONDUCT`}</h1>
    <p>{`All attendees, speakers, sponsors and volunteers at our conference are required to agree with the following code of conduct.
Organizers will enforce this code throughout the event.
We are expecting cooperation from all participants to help ensuring a safe environment for everybody.`}</p>
    <h2>{`NEED HELP?`}</h2>
    <p>{`We have a dedicated team that takes care of your safety.
If you experience harassment or other Code of Conduct violations, please contact the care team.`}</p>
    <p>{`Talk to us in person at the conference venue or the evening events.
Members of the organization can be recognized by the Armada JS t-shirts they wear.
Write a Twitter direct message to our Armada JS Twitter account: (`}<a parentName="p" {...{
        "href": "https://twitter.com/armada_js"
      }}>{`@armada_js`}</a>{`).
Write an email to the organization team (`}<a parentName="p" {...{
        "href": "mailto:contact@armada-js.com"
      }}>{`contact@armada-js.com`}</a>{`), give us a phone call to +381 63 607 127 or send an SMS.`}</p>
    <h2>{`BEHAVIOR`}</h2>
    <p>{`Harassment includes offensive verbal comments related to gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion, technology choices, sexual images in public spaces, deliberate intimidation, stalking, following, harassing photography or recording, sustained disruption of talks or other events, inappropriate physical contact, and unwelcome sexual attention.`}</p>
    <p>{`Participants asked to stop any harassing behavior are expected to comply immediately.`}</p>
    <p>{`Sponsors are also subject to the anti-harassment policy.
In particular, sponsors should not use sexualized images, activities, or other material. Booth staff (including volunteers) should not use sexualized clothing/uniforms/costumes, or otherwise create a sexualized environment.`}</p>
    <p>{`If a participant engages in harassing behavior, the conference organizers may take any action they deem appropriate, including warning the offender or expulsion from the conference with no refund.`}</p>
    <p>{`If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact a member of conference staff immediately.
Conference staff can be identified as they'll be wearing branded t-shirts.`}</p>
    <p>{`Conference staff will be happy to help participants contact hotel/venue security or local law enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the duration of the conference.
We value your attendance.`}</p>
    <h2>{`SOCIAL MEDIA`}</h2>
    <p>{`In case of feeling offended or harassed by an attendee or speaker, please don't let a social media harassment happen.
We would like to create a safe space for everyone and a tweetstorm or something similar is not an option to make someone feel safe.
We are all humans, we all make mistakes.
It would be great if you reach out to a staff member, send a private Twitter message `}<a parentName="p" {...{
        "href": "https://twitter.com/armada_js"
      }}>{`@armada_js`}</a>{` or just give us a call to the above mentioned phone if you don't feel comfortable to talk to one of us in person.`}</p>
    <h2>{`CONCLUSION`}</h2>
    <p>{`We expect participants to follow these rules at conference and workshop venues and conference-related social events.`}</p>
    <p>{`Original source and credit: RuhrJS.de, JSconf.eu & The Ada Initiative`}</p>
    <p>{`This work is licensed under a Creative Commons Attribution 3.0 Unported License`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      